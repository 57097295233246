import { createApp } from 'vue';
import moment from 'moment';
import App from './App.vue';
import store from './store/store';
import router from './routes/router';
import { VueQueryPlugin } from 'vue-query';

moment.locale('sv');
const app = createApp(App);
app.config.errorHandler = (err) => {
  if (err && !err.code) {
    throw err;
  }
};
app.use(VueQueryPlugin);
app.use(router);
app.use(store);
app.mount('#app');
app.config.globalProperties.$moment = moment;
