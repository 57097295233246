import { ServiceBase } from '@sgf/go-api-services';

class PresentationApi extends ServiceBase {
  _origin = 'presentationApi';

  async getPresentation(context, { clubId, presentationId }) {
    return await this.fetch(
      `/clubs/${clubId}/presentations/${presentationId}`,
      {
        method: 'GET',
      },
      context,
      'getPresentation',
      'Hämtar...'
    );
  }
}

export default PresentationApi;
