<template>
  <AppShell id="app">
    <router-view v-slot="{ Component }">
      <transition :name="transitionName">
        <component :key="routeKey" :is="Component" />
      </transition>
    </router-view>
    <AppApiView />
    <IconsAtom />
  </AppShell>
</template>

<script>
import { AppShell } from '@sgf/go-ace-ui';
import { mapActions } from 'vuex';
import presentationTypes from './modules/presentation/store/types';
import AppApiView from './modules/common/components/views/AppApiView.vue';
import { IconsAtom } from './modules/common/components/atoms';
export default {
  components: {
    AppShell,
    AppApiView,
    IconsAtom,
  },
  data() {
    return {
      transitionName: undefined,
    };
  },
  computed: {
    routeKey() {
      return this.$route.fullPath;
    },
  },
  methods: {
    ...mapActions({
      initialize: presentationTypes.actions.INITIALIZE,
    }),
  },
  watch: {
    $route(nv, ov) {
      if ((ov.name === undefined && !nv.name.includes('legacy')) || (ov.name && ov.name.includes('legacy'))) {
        this.initialize(nv?.params);
      } else if (nv.name === 'slide') {
        this.transitionName = 'slide';
      }
    },
  },
};
</script>

<style>
body {
  overflow: hidden;
}
@import '../node_modules/@sgf/go-fonts/fonts.css';
.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}

@font-face {
  font-family: 'BrixSansRegular';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url('/public/assets/fonts/brix-sans-regular.woff2') format('woff2'), url('/public/assets/fonts/brix-sans-regular.woff') format('woff'),
    url('/public/assets/fonts/brix-sans-regular.otf') format('opentype');
}
</style>
