import { createStore } from 'vuex';
import { apiStore, apiTypes } from '@sgf/go-api-services';
import presentationStore from '../modules/presentation/store';
import slideStore from '../modules/slide/store';

apiStore.state.baseUrl = process.env.VUE_APP_APIENDPOINT;

const store = createStore({
  modules: {
    [apiTypes.namespace]: apiStore,
    [presentationStore.namespace]: presentationStore,
    [slideStore.namespace]: slideStore,
  },
});

export default store;
