export const getScrollDuration = (slide) => {
  if (!slide) {
    return undefined;
  }
  const { transitionType, type } = slide.configuration;
  if (!transitionType || !type || transitionType !== 'scroll') {
    return undefined;
  }
  if (type === 'bookings') {
    return slide.data.timeSlots.length * slide.configuration.scrollSpeed;
  } else if (type === 'competitionStartList') {
    return slide.data.startTimes.length * slide.configuration.scrollSpeed;
  } else if (type === 'competitionResult') {
    return slide.data.competitionResultList.length * slide.configuration.scrollSpeed;
  }
  return undefined;
};

export const scroll = (container, duration, callback) => {
  if (duration && container.scrollHeight > container.clientHeight) {
    setTimeout(() => {
      smoothScroll(container, duration, callback);
    }, 2000);
  }
};

const smoothScroll = (element, duration, callback) => {
  const start = element.scrollTop;
  const end = element.scrollHeight - element.clientHeight;
  const distance = end - start;
  const startTime = performance.now();
  const step = (currentTime) => {
    if (!element) {
      console.log('Failsafe: no element');
      return;
    }
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);
    element.scrollTop = Math.round(start + distance * progress);
    if (progress < 1) {
      requestAnimationFrame(step);
    } else {
      setTimeout(() => {
        callback();
      }, 2000);
    }
  };
  requestAnimationFrame(step);
};
