import { createRouter, createWebHashHistory } from 'vue-router';
const StartPage = () => import('../modules/common/components/pages/StartPage');
const LegacyLandingPage = () => import('../modules/common/components/pages/LegacyLandingPage');
const SlidePage = () => import('../modules/slide/components/pages/SlidePage');

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/:pid/:clubId',
      name: 'legacyLandingPage',
      component: LegacyLandingPage,
    },
    {
      path: '/:pid/:clubId/competition-result/:id',
      name: 'legacyCompetitionResultLandingPage',
      component: LegacyLandingPage,
    },
    {
      path: '/:pid/:clubId/competition-start-list/:id',
      name: 'legacyCompetitionStartListLandingPage',
      component: LegacyLandingPage,
    },
    {
      path: '/:pid/:clubId/bookings/:id',
      name: 'legacyBookingsLandingPage',
      component: LegacyLandingPage,
    },
    {
      path: '/:pid/:clubId/image/:id',
      name: 'legacyImageLandingPage',
      component: LegacyLandingPage,
    },
    {
      path: '/presentation/:clubId?/:presentationId?',
      name: 'start',
      component: StartPage,
    },
    {
      path: '/presentation/:clubId/:presentationId/slide/:slideId?/:ts?',
      name: 'slide',
      component: SlidePage,
    },
  ],
  scrollBehavior() {},
});
