import { _types } from './types';
import { getPages } from '../helpers/pagingHelper';
import { getScrollDuration } from '../helpers/scrollHelper';
export default {
  [_types.getters.GET_SLIDE]: (state, getters, rootState) => {
    const { id, slideId, slidesById } = rootState.presentation;
    const { slideData } = state;
    if (!id || !slideId || !slidesById || !slideData) {
      return undefined;
    }
    const slideConfiguration = slidesById[slideId];
    const data = slideData[slideId];
    if (!slideConfiguration || !data) {
      return undefined;
    }
    return {
      configuration: slideConfiguration,
      data: data,
    };
  },
  [_types.getters.GET_PAGES]: (state, getters) => {
    const { containerHeight } = state;
    return getPages(getters[_types.getters.GET_SLIDE], containerHeight);
  },
  [_types.getters.GET_SCROLL_DURATION]: (state, getters) => {
    const slide = getters[_types.getters.GET_SLIDE];
    var result = getScrollDuration(slide);
    return result;
  },
};
