<template>
  <div class="header-values-layout-atom">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.header-values-layout-atom {
  display: flex;
  flex-direction: column;
}
</style>
