import { _types } from './types';
import { getGuid } from '../../common/helpers/guidHelper';
export default {
  [_types.mutations.SET_SLIDE_DATA]: (state, { slideId, slideData }) => {
    if (slideData) {
      if (slideData.timeSlots) {
        slideData.timeSlots = slideData.timeSlots.map((x) => {
          return { ...x, id: getGuid() };
        });
      }
      state.slideData[slideId] = slideData;
    } else {
      state.slideData[slideId] = {};
    }

    state.slideTimers[slideId] = new Date().getTime();
  },
  [_types.mutations.SET_CONTAINER_HEIGHT]: (state, height) => {
    state.containerHeight = height;
  },
  [_types.mutations.SET_FIELD]: (state, { field, value }) => {
    state[field] = value;
  },
  [_types.mutations.INITIALIZE]: (state) => {
    state.currentPage = 1;
    state.currentIteration = 1;
    state.timeoutRef = undefined;
  },
};
