export const shouldFetchSlideData = (timer) => {
  if (timer === undefined) {
    return true;
  }
  const currentTime = new Date().getTime();
  const cacheThreshold = 10 * 60 * 1000;
  if (currentTime - timer >= cacheThreshold) {
    return true;
  }
  return false;
};
