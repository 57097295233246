<template>
  <component :is="tag" class="header-value-atom">
    {{ value }}
  </component>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
      validator: (value) => ['subheader', 'default'].includes(value),
    },
    value: String,
  },
  computed: {
    tag() {
      if (this.type === 'subheader') {
        return 'h2';
      }
      return 'span';
    },
  },
};
</script>

<style scoped>
h2.header-value-atom {
  color: var(--color-gray-medium);
  font-size: 1.5rem;
  line-height: 1.5rem;
}
span.header-value-atom {
  font-size: 1.4rem;
  color: var(--color-text-primary);
}
</style>
