<template>
  <svg width="0" height="0" style="display: none">
    <symbol id="check-circle" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path
        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
      />
    </symbol>
    <symbol id="check-circle-empty" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
    </symbol>
    <symbol
      id="loader"
      width="39"
      height="39"
      fill="currentColor"
      viewBox="0 0 399.387 399.387"
      style="enable-background: new 0 0 399.387 399.387"
      xml:space="preserve"
    >
      <path
        d="M340.896 58.488C303.18 20.771 253.033 0 199.694 0 146.353 0 96.207 20.771 58.491 58.488 20.772 96.206 0 146.354 0 199.693c0 53.342 20.772 103.489 58.491 141.206 37.716 37.717 87.863 58.488 141.203 58.488 53.337 0 103.486-20.771 141.203-58.488 37.719-37.718 58.49-87.865 58.49-141.206 0-53.338-20.772-103.486-58.491-141.205zM199.694 77.457c67.402 0 122.236 54.835 122.236 122.236s-54.834 122.236-122.236 122.236S77.457 267.094 77.457 199.693 132.292 77.457 199.694 77.457zm128.367 250.605c-34.289 34.287-79.877 53.17-128.367 53.17-48.491 0-94.079-18.883-128.367-53.17-34.289-34.287-53.173-79.877-53.173-128.37h41.148c0 77.411 62.979 140.391 140.392 140.391 77.412 0 140.39-62.979 140.39-140.391 0-77.412-62.979-140.391-140.39-140.391-4.594 0-9.134.229-13.615.662v-41.31c4.508-.332 9.049-.5 13.615-.5 48.49 0 94.078 18.883 128.367 53.171 34.289 34.289 53.172 79.878 53.172 128.368-.001 48.494-18.883 94.083-53.172 128.37z"
      />
    </symbol>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
