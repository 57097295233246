import { _types } from './types';
import SlideApi from '../api/slideApi';
import { getSlideDuration } from '../helpers/slideChangeHelper';
import { shouldFetchSlideData } from '../helpers/cacheHelper';
import presentationTypes from '../../presentation/store/types';
const slideApi = new SlideApi();
export default {
  [_types.actions.FETCH_SLIDE_DATA]: async (context) => {
    context.commit(_types.mutations.INITIALIZE);
    try {
      const { slidesById, slideId, clubId } = context.rootState.presentation;
      const { slideTimers } = context.state;
      const slide = slidesById[slideId];
      const currentTimer = slideTimers[slideId];
      if (slide && shouldFetchSlideData(currentTimer)) {
        const slideData = await slideApi.fetchSlide(context, { clubId, slide });
        context.commit(_types.mutations.SET_SLIDE_DATA, { slideId, slideData });
      }
    } catch (e) {
      context.dispatch(_types.actions.START_DEFAULT_TIMER);
    }
    if (context.state.containerHeight || context.getters[_types.getters.GET_SLIDE]?.configuration.type === 'image') {
      context.dispatch(_types.actions.START_DEFAULT_TIMER);
    }
  },
  [_types.actions.SET_CONTAINER_HEIGHT]: (context, height) => {
    context.commit(_types.mutations.SET_CONTAINER_HEIGHT, height);
    if (height) {
      context.dispatch(_types.actions.START_DEFAULT_TIMER);
    }
  },
  [_types.actions.SET_FIELD]: (context, fieldValue) => {
    context.commit(_types.mutations.SET_FIELD, fieldValue);
  },
  [_types.actions.START_DEFAULT_TIMER]: (context) => {
    if (context.state.timeoutRef) {
      return;
    }
    let duration = 5000;
    const slide = context.getters[_types.getters.GET_SLIDE];
    if (slide) {
      const { configuration, data } = slide;
      const pages = context.getters[_types.getters.GET_PAGES];
      const scrollDuration = context.getters[_types.getters.GET_SCROLL_DURATION];
      duration = getSlideDuration({ configuration, data, pages, scrollDuration });
    }
    const timeoutRef = setTimeout(() => {
      context.dispatch(presentationTypes.actions.CHANGE_SLIDE, undefined, { root: true });
    }, duration);
    context.commit(_types.mutations.SET_FIELD, { field: 'timeoutRef', value: timeoutRef });
  },
};
