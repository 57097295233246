import { chunkArray } from '../../common/helpers/arrayHelper';
export const getPages = (slide, height) => {
  if (!slide) {
    return undefined;
  }
  const { transitionType, type } = slide.configuration;
  if (!transitionType || !type || transitionType !== 'pagination') {
    return undefined;
  }
  const paddingHeight = 32;
  const headerHeight = 55;
  const footerHeight = 86;

  if (type === 'bookings') {
    const rowHeight = 132;
    const itemsPerPage = Math.floor((height - paddingHeight - headerHeight - footerHeight) / rowHeight);
    const chunkedData = chunkArray(slide.data.timeSlots, itemsPerPage);
    return chunkedData;
  } else if (type === 'competitionStartList') {
    const rowHeight = 52;
    const itemsPerPage = Math.floor((height - paddingHeight - headerHeight - footerHeight) / rowHeight);
    const chunkedData = chunkArray(slide.data.startTimes, itemsPerPage);
    return chunkedData;
  } else if (type === 'competitionResult') {
    const rowHeight = 52;
    const itemsPerPage = Math.floor((height - paddingHeight - headerHeight - footerHeight) / rowHeight);
    const chunkedData = chunkArray(slide.data.competitionResultList, itemsPerPage);
    return chunkedData;
  }
  return undefined;
};
