import { _types } from './types';
import PresentationApi from '../api/presentationApi';
import router from '../../../routes/router';
import { setColors } from '../helpers/colorsHelper';
import slideTypes from '../../slide/store/types';
const api = new PresentationApi();
export default {
  [_types.actions.INITIALIZE]: async (context, { clubId, presentationId, slideId } = {}) => {
    if (!clubId || !presentationId) {
      return;
    }
    const result = await api.getPresentation(context, { clubId, presentationId });
    setColors(result?.colors);
    context.commit(_types.mutations.INITIALIZE, { presentation: result, clubId: clubId, slideId: slideId });
    if (!slideId) {
      context.dispatch(_types.actions.CHANGE_SLIDE);
    } else {
      context.dispatch(slideTypes.actions.FETCH_SLIDE_DATA, {}, { root: true });
    }
  },
  [_types.actions.CHANGE_SLIDE]: (context) => {
    const { slides, slideId, clubId, id } = context.state;
    if (!slides) {
      return;
    }
    if (!slideId) {
      context.commit(_types.mutations.SET_SLIDE, slides[0]);
    } else {
      const lastIndex = slides.length - 1;
      const currentIndex = slides.indexOf(slideId);
      if (currentIndex === lastIndex) {
        context.commit(_types.mutations.SET_SLIDE, slides[0]);
      } else {
        context.commit(_types.mutations.SET_SLIDE, slides[currentIndex + 1]);
      }
    }
    const routeParams = { clubId: clubId, presentationId: id, slideId: context.state.slideId };
    router.push({ name: 'slide', params: routeParams });
    context.dispatch(slideTypes.actions.FETCH_SLIDE_DATA, {}, { root: true });
  },
};
