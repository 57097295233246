<template>
  <table class="table-atom">
    <thead class="table-atom__head">
      <tr class="table-atom__head__row">
        <th
          class="table-atom__head__row__header"
          v-for="(column, index) in columns"
          :key="index"
          :style="{
            width: column.width ? column.width : 'auto',
            'min-width': column.minWidth ? column.minWidth : 'auto',
            'text-align': column.align ? column.align : 'left',
          }"
        >
          {{ column.header }}
        </th>
      </tr>
    </thead>
    <tbody class="table-atom__body">
      <tr class="table-atom__body__row" v-for="(row, rowIndex) in data" :key="'data-row-' + rowIndex + '-' + row.id">
        <td class="table-atom__body__row__cell" v-for="(column, cellIndex) in columns" :key="cellIndex">
          <slot :value="row" :name="column.key">
            <span
              class="table-atom__body__row__cell__value ace-clamp"
              :style="{
                'text-align': column.align ? column.align : 'left',
              }"
              >{{ row[column.key] }}</span
            >
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    columns: Array,
    data: Array,
  },
};
</script>

<style scoped>
.table-atom {
  width: 100%;
  table-layout: auto;
}
.table-atom__head {
  font-size: 1.4rem;
  text-align: left;
  background-color: var(--color-accent);
  color: var(--color-table-header-text);
}
.table-atom__head__row {
  border-bottom: 1.5px solid var(--color-blue-dark);
}
.table-atom__head__row__header {
  padding: 0.75rem;
  font-family: 'BrixSansRegular';
  white-space: nowrap;
}
.table-atom__body {
}
.table-atom__body__row {
  background-color: var(--color-blue-dark);
  color: var(--color-text-primary);
}
.table-atom__body__row:nth-child(even) {
  background-color: var(--color-blue-light);
}
.table-atom__body__row__cell {
  padding: 0.75rem;
  font-family: 'BrixSansRegular';
  font-size: 1.4rem;
  vertical-align: top;
}
.table-atom__body__row__cell__value {
}
</style>
