import { ServiceBase } from '@sgf/go-api-services';
import { processSlideQuery } from '../helpers/slideQueryHelper';
import { apiTypes } from '@sgf/go-api-services';

class SlideApi extends ServiceBase {
  _origin = 'slideApi';

  async fetchSlide(context, { clubId, slide }) {
    const params = processSlideQuery(slide);
    context.dispatch(apiTypes.actions.CLEAR_ERROR, slide.id, { root: true });
    return await this.fetch(
      `/slideByQuery/${clubId}/${slide.id}${params}`,
      {
        method: 'GET',
      },
      context,
      slide.id,
      'Hämtar...'
    );
  }
}

export default SlideApi;
