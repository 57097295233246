import { TypesBase } from '@sgf/go-utilities';

class PresentationTypes extends TypesBase {
  constructor() {
    super('presentation');
  }

  getters = {};

  mutations = {
    INITIALIZE: 'initialize',
    SET_SLIDE: 'setSlide',
  };

  actions = {
    INITIALIZE: 'initialize',
    CHANGE_SLIDE: 'changeSlide',
  };
}

class NamespacedPresentationTypes extends PresentationTypes {
  constructor() {
    super();
    this.toNamespaced();
  }
}

const types = new NamespacedPresentationTypes();
const _types = new PresentationTypes();
export { _types };
export default types;
