import { _types } from './types';

export default {
  [_types.mutations.INITIALIZE]: (state, { presentation, clubId, slideId }) => {
    const { colors, name, id, showLogo, slides } = presentation;
    state.colors = colors;
    state.name = name;
    state.id = id;
    state.showLogo = showLogo;
    state.slidesById = slides.reduce((pv, cv) => {
      pv[cv.id] = cv;
      return pv;
    }, {});
    state.slides = slides.map((x) => x.id);
    state.clubId = clubId;
    state.slideId = slideId;
  },
  [_types.mutations.SET_SLIDE]: (state, slideId) => {
    state.slideId = slideId;
  },
};
