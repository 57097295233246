import { TypesBase } from '@sgf/go-utilities';

class SlideTypes extends TypesBase {
  constructor() {
    super('slide');
  }

  getters = {
    GET_SLIDE: 'getSlide',
    GET_PAGES: 'getPages',
    GET_SCROLL_DURATION: 'getScrollDuration',
  };

  mutations = {
    INITIALIZE: 'initialize',
    SET_FIELD: 'setField',
    SET_SLIDE_DATA: 'setSlideData',
    SET_CONTAINER_HEIGHT: 'setContainerHeight',
  };

  actions = {
    SET_FIELD: 'setField',
    FETCH_SLIDE_DATA: 'fetchSlideData',
    SET_CONTAINER_HEIGHT: 'setContainerHeight',
    START_DEFAULT_TIMER: 'startDefaultTimer',
  };
}

class NamespacedSlideTypes extends SlideTypes {
  constructor() {
    super();
    this.toNamespaced();
  }
}

const types = new NamespacedSlideTypes();
const _types = new SlideTypes();
export { _types };
export default types;
