<template>
  <div class="background-atom" :class="backgroundClass">
    <slot v-if="isLoaded"></slot>
  </div>
</template>

<script>
export default {
  props: {
    isLoaded: Boolean,
    type: {
      type: String,
      default: 'default',
      validator: (v) => {
        return ['default', 'bookings', 'competitionStartList', 'competitionResult', 'image'];
      },
    },
  },
  computed: {
    backgroundClass() {
      switch (this.type) {
        case 'competitionResult':
          return 'background-atom--2';
        case 'competitionStartList':
          return 'background-atom--3';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.background-atom {
  background: url('/public/assets/images/backgrounds/1.jpg');
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}
.background-atom--2 {
  background: url('/public/assets/images/backgrounds/2.jpg');
}
.background-atom--3 {
  background: url('/public/assets/images/backgrounds/3.jpg');
}
</style>
