<template>
  <div class="page-transition-atom">
    <transition-group name="slide-page">
      <template v-for="(p, i) in pages" :key="i">
        <div class="page-transition-atom__page-container" v-if="i === currentPage - 1">
          <slot name="page" :value="p" />
        </div>
      </template>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    pages: Array,
    currentPage: Number,
  },
};
</script>

<style scoped>
.page-transition-atom {
  position: relative;
}

.page-transition-atom__page-container {
  width: 100%;
}

.slide-page-enter-active,
.slide-page-leave-active {
  transition: all 1s ease-out;
}

.slide-page-enter-to {
  position: absolute;
  right: 0;
  opacity: 1;
}

.slide-page-enter-from {
  position: absolute;
  right: -100%;
  opacity: 0;
}

.slide-page-leave-to {
  position: absolute;
  left: -100%;
  opacity: 0;
}

.slide-page-leave-from {
  position: absolute;
  left: 0;
  opacity: 1;
}
</style>
