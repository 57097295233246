export const setColors = (colors) => {
  let r = document.querySelector(':root');

  let primary = colors?.primary;
  let secondary = colors?.secondary;
  let textPrimary = colors?.textPrimary;
  let textSecondary = colors?.textSecondary;
  let grayPrimary = colors?.grayPrimary;
  let graySecondary = colors?.graySecondary;
  let accent = colors?.accent;

  r.style.setProperty('--color-blue-dark', primary ? primary : '#384b68');
  r.style.setProperty('--color-blue-light', secondary ? secondary : '#50688d');
  r.style.setProperty('--color-text-primary', textPrimary ? textPrimary : '#ffffff');
  r.style.setProperty('--color-table-header-text', textSecondary ? textSecondary : '#2c3748');
  r.style.setProperty('--color-primary-gray', grayPrimary ? grayPrimary : '#f4f4f4');
  r.style.setProperty('--color-gray-medium', graySecondary ? graySecondary : '#d7e4f8');
  r.style.setProperty('--color-accent', accent ? accent : '#edce47');
};
