export const getSlideDuration = ({ configuration, data, pages, scrollDuration }) => {
  if (configuration.type === 'image') {
    return configuration.duration * 1000;
  }
  if (!data) {
    return 5000;
  }
  if (configuration.transitionType === 'pagination') {
    return configuration.transitionRepetition * (pages.length * configuration.paginationSpeed * 1000);
  }
  if (scrollDuration) {
    return scrollDuration + 4000;
  }
  return 10000;
};
