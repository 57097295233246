export const chunkArray = (array, size) => {
  return array.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(array.slice(i, i + size));
    return acc;
  }, []);
};

export const findInChunk = (chunkedArray, value) => {
  for (let i = 0; i < chunkedArray.length; i++) {
    if (chunkedArray[i].includes(value)) {
      return {
        chunk: chunkedArray[i],
        index: i,
      };
    }
  }
  return null;
};
