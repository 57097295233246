<template>
  <div class="app-api-view" v-if="isVisible">
    <LoaderAtom v-if="isLoaderActive" mode="fullscreen" />
    <div class="app-api-view__message-container">
      <InformationMolecule v-if="isMessageActive" header="OBS!" :text="message" />
    </div>
  </div>
</template>

<script>
import { LoaderAtom, InformationMolecule } from '@sgf/go-ace-ui';
import { mapState } from 'vuex';

export default {
  components: {
    LoaderAtom,
    InformationMolecule,
  },
  computed: {
    ...mapState('presentation', ['id', 'slideId']),
    ...mapState('slide', ['slideData']),
    ...mapState('api', ['errors']),
    hasError() {
      if (this.errors[this.slideId]) {
        return true;
      }
      return this.errors['getPresentation'] !== undefined;
    },
    hasRequiredParams() {
      const { clubId, presentationId } = this.$route?.params;
      if (this.$route.name && (!clubId || !presentationId)) {
        return false;
      }
      return true;
    },
    isLoaderActive() {
      if (!this.errors[this.slideId] && this.slideId && this.slideData[this.slideId] === undefined) {
        return true;
      }
      return !this.id && this.hasRequiredParams && !this.hasError;
    },
    isMessageActive() {
      return !this.hasRequiredParams || this.hasError;
    },
    message() {
      if (this.hasError) {
        return 'Det uppstod ett fel vid hämtningen av bildspelet. Vänligen försök igen senare.';
      }
      return 'Vi kunde tyvärr inte hitta bildspelet som du letade efter. Vänligen kontrollera att länken är korrekt och försök igen.';
    },
    isVisible() {
      return this.isLoaderActive || this.isMessageActive;
    },
  },
};
</script>

<style scoped>
.app-api-view {
  backdrop-filter: blur(4px);
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-api-view__message-container {
  padding: 0 40px;
}
</style>
