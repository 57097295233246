export const processSlideQuery = (slide) => {
  if (!slide) {
    return '';
  }
  const { paginationSpeed, scrollSpeed, competitionId, startListId, classId, type, courseId, bookingSpan, competitionResultType } = slide;
  const params = [];
  if (paginationSpeed) {
    params.push(`paginationSpeed=${paginationSpeed}`);
  }
  if (scrollSpeed) {
    params.push(`scrollSpeed=${scrollSpeed}`);
  }
  if (competitionId) {
    params.push(`competitionId=${competitionId}`);
  }
  if (startListId) {
    params.push(`startListId=${startListId}`);
  }
  if (classId) {
    params.push(`classId=${classId}`);
  }
  if (type) {
    params.push(`type=${type}`);
  }
  if (competitionResultType) {
    params.push(`competitionResultType=${competitionResultType}`);
  }
  if (courseId) {
    params.push(`courseId=${courseId}`);
  }
  if (bookingSpan) {
    params.push(`bookingSpan=${bookingSpan}`);
  }
  return `?${params.join('&')}`;
};
